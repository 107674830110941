import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { Checkbox, FormItem } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'

import { OISAgreement } from './OISAgreement'
import { PersonalDataAgreement } from './PersonalDataAgreement'
import { PrivacyAgreement } from './PrivacyAgreement'
import { RiskNotification } from './RiskNotification'
import { UserAgreement } from './UserAgreements'

export const agreements = {
    oisAgreement: 'oisAgreement',
    personalDataAgreement: 'personalDataAgreement',
    riskAgreement: 'riskAgreement',
    userAgreement: 'userAgreement',
    privacyAgreement: 'privacyAgreement',
}

interface IComponentProps {
    isDisabled?: boolean
    withoutPD?: boolean
}

export const Agreements: FC<IComponentProps> = ({ isDisabled, withoutPD }) => {
    const usersAgreements = useSelector(authSelector.selectAgreements)
    const [personalDataAgreementModal, setPersonalDataAgreementModal] = useState(false)
    const [userAgreementModal, setUserAgreementModal] = useState(false)
    const [riskNotificationModal, setRiskNotificationModal] = useState(false)
    const [oisModal, setOisModal] = useState(false)
    const [privacyAgreementModal, setPrivacyAgreementModal] = useState(false)

    return (
        <>
            {!usersAgreements?.personal_data_agreement && (
                <FormItem
                    className="no-space no-error-field"
                    name={agreements.personalDataAgreement}
                    valuePropName="checked"
                >
                    <Checkbox disabled={isDisabled}>
                        Даю согласие на обработку{' '}
                        <span
                            onClick={(e) => {
                                e.preventDefault()
                                setPersonalDataAgreementModal(true)
                            }}
                            className="link-color"
                        >
                            персональных данных
                        </span>
                    </Checkbox>
                </FormItem>
            )}
            {!usersAgreements?.user_agreement && (
                <FormItem
                    className="no-space no-error-field"
                    name={agreements.userAgreement}
                    valuePropName="checked"
                >
                    <Checkbox disabled={isDisabled}>
                        Я согласен с условиями{' '}
                        <span
                            onClick={(e) => {
                                e.preventDefault()
                                setUserAgreementModal(true)
                            }}
                            className="link-color"
                        >
                            пользовательского соглашения
                        </span>
                    </Checkbox>
                </FormItem>
            )}

            {!usersAgreements?.rules_agreement && (
                <FormItem
                    className="no-space no-error-field"
                    name={agreements.oisAgreement}
                    valuePropName="checked"
                >
                    <Checkbox disabled={isDisabled}>
                        Я согласен с{' '}
                        <span
                            onClick={(e) => {
                                e.preventDefault()
                                setOisModal(true)
                            }}
                            className="link-color"
                        >
                            правилами ОИС
                        </span>
                    </Checkbox>
                </FormItem>
            )}

            {!usersAgreements?.risk_agreement && (
                <FormItem
                    className="no-space no-error-field"
                    name={agreements.riskAgreement}
                    valuePropName="checked"
                >
                    <Checkbox disabled={isDisabled}>
                        Я уведомлен(а){' '}
                        <span
                            onClick={(e) => {
                                e.preventDefault()
                                setRiskNotificationModal(true)
                            }}
                            className="link-color"
                        >
                            о рисках
                        </span>
                    </Checkbox>
                </FormItem>
            )}

            {!usersAgreements?.confidentiality_agreement && (
                <FormItem
                    className="no-space no-error-field"
                    name={agreements.privacyAgreement}
                    valuePropName="checked"
                >
                    <Checkbox disabled={isDisabled}>
                        Я ознакомлен(а) с{' '}
                        <span
                            onClick={(e) => {
                                e.preventDefault()
                                setPrivacyAgreementModal(true)
                            }}
                            className="link-color"
                        >
                            Соглашением о конфиденциальности
                        </span>
                    </Checkbox>
                </FormItem>
            )}

            <PersonalDataAgreement
                isModalVisible={personalDataAgreementModal}
                setIsModalVisible={setPersonalDataAgreementModal}
            />
            <UserAgreement
                isModalVisible={userAgreementModal}
                setIsModalVisible={setUserAgreementModal}
            />
            <RiskNotification
                isModalVisible={riskNotificationModal}
                setIsModalVisible={setRiskNotificationModal}
            />
            <OISAgreement isModalVisible={oisModal} setIsModalVisible={setOisModal} />

            <PrivacyAgreement
                isModalVisible={privacyAgreementModal}
                setIsModalVisible={setPrivacyAgreementModal}
            />
        </>
    )
}
