import { FC, memo, useEffect } from 'react'
import ModalContainer from 'react-modal-promise'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getCurrentUserAction } from '@store/auth'

import { Agreements } from '@pages/Agreements'
import ClientCard from '@pages/ClientCard'
import { CreateQualificationStatement } from '@pages/CreateQualificationStatement'
import EmissionCardPage from '@pages/DFAEmission'
import { DFAEncumbranceCreate, DFAEncumbranceView } from '@pages/DFAEncumbrance'
import DFAIssueDecision from '@pages/DFAIssueDecisionPage'
import { EarlyRepayment, EarlyRepaymentCreate } from '@pages/EarlyRepayment'
import MyEmissions from '@pages/MyEmissions/MyEmissions'
import Offers from '@pages/Offers/Offers'
import {
    CreateDFAOwnershipTransferRequestPage,
    DFAOwnershipTransferRequestPage,
} from '@pages/OwnershipTransfer'
import { QualificationStatement } from '@pages/QualificationStatement'
import { QuestionnaireRoutes } from '@pages/Questionnaire'
import Register from '@pages/Register'
import { RemoveFromUsers } from '@pages/RemoveFromUsers'
import {
    Businessman,
    ClientRepresentative,
    Individual,
    LegalEntity,
    RoleForms,
} from '@pages/RoleForms'

import { RoleFormsLayout } from '@components/Layout'
import { AppLayout } from '@components/Layout/AppLayout'
import { PureLayout } from '@components/Layout/PureLayout'
import { RootLayout } from '@components/Layout/RootLayout'

const App: FC = memo(() => {
    const reduxDispatch = useDispatch()

    useEffect(() => {
        reduxDispatch(getCurrentUserAction())
    }, [])

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<RootLayout />}>
                    <Route element={<PureLayout />}>
                        <Route path="/update-agreements" element={<Agreements />} />
                        <Route path="/registration" element={<Register />} />
                    </Route>

                    <Route path="/role-forms" element={<RoleFormsLayout />}>
                        <Route index element={<RoleForms />} />
                        <Route path="individual" element={<Individual />} />
                        <Route path="businessman" element={<Businessman />} />
                        <Route path="client-representative" element={<ClientRepresentative />} />
                        <Route path="legal-entity" element={<LegalEntity />} />
                    </Route>

                    <Route element={<AppLayout />}>
                        {<Route index element={<Navigate to="offers" />} />}
                        <Route path="offers" element={<Offers />} />
                        <Route path="offers/:dfa">
                            <Route index element={<EmissionCardPage />} />
                            <Route path="encumbrance" element={<DFAEncumbranceCreate />} />
                        </Route>
                        <Route path="early-repayment/create" element={<EarlyRepaymentCreate />} />
                        <Route path="early-repayment/:repayment" element={<EarlyRepayment />} />
                        <Route
                            path="ownership-transfer-request"
                            element={<CreateDFAOwnershipTransferRequestPage />}
                        />
                        <Route
                            path="ownership-transfer-request/:request"
                            element={<DFAOwnershipTransferRequestPage />}
                        />
                        <Route path="issue-decision" element={<DFAIssueDecision />} />
                        <Route path="emissions" element={<MyEmissions />} />
                        <Route path="encumbrance/:id" element={<DFAEncumbranceView />} />
                        <Route path="clients/:id" element={<ClientCard />} />
                        <Route
                            path="qualification-statement"
                            element={<CreateQualificationStatement />}
                        />
                        <Route
                            path="qualification-statement/:id"
                            element={<QualificationStatement />}
                        />

                        <Route path="questionnaire/*" element={<QuestionnaireRoutes />} />
                        <Route path="remove-from-users" element={<RemoveFromUsers />} />
                    </Route>
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: '1rem' }}>
                                <p>404</p>
                            </main>
                        }
                    />
                </Route>
            </Routes>

            <ModalContainer />
        </div>
    )
})

export default App
