import { Box, ConditionsModal, LinkDocument } from '@dltru/dfa-ui'
import { FC } from 'react'

import { AgreementSection } from './components/AgreementSection'
import { AgreementSectionList } from './components/AgreementSectionList'
import { TwoColumnRow } from './components/TwoColumnRow'
import './style.less'
import { IAgreementModal } from './types'

export const PersonalDataAgreement: FC<IAgreementModal> = ({ ...props }) => {
    return (
        <ConditionsModal
            title={<Box align="center">СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</Box>}
            width={740}
            {...props}
            text={
                <Box padding={[0, 0, 0, 48]}>
                    <AgreementSectionList>
                        <AgreementSection>
                            В настоящем согласии на обработку персональных данных,если из текста
                            прямо не вытекает иное, следующие термины будут иметь указанные ниже
                            значения:
                            <TwoColumnRow title="«Оператор ПД»" headerWidth={30}>
                                Общество с ограниченной ответственностью «Системы распределенного
                                реестра»: <br />
                                адрес: 121099;
                                <br />
                                Россия,г.Москва,вн.тер.г.муниципальный округ Арбат,
                                ул.Композиторская, д. 17, эт./пом. 7/I, ком. 11 – 17;
                                <br />
                                ОГРН: 1217700216360;
                                <br />
                                ИНН: 9704063885.
                            </TwoColumnRow>
                            <TwoColumnRow title="«Персональные данные»" headerWidth={30}>
                                следующие персональные данные Заявителя:
                                <ol className="indent-marker">
                                    <li>фамилия,имя,отчество(при наличии);</li>
                                    <li>дата и место рождения;</li>
                                    <li>логин, используемый при Аутентификации;</li>
                                    <li>адрес электронной почты;</li>
                                    <li>номер телефона;</li>
                                    <li>реквизиты документа, удостоверяющего личность;</li>
                                    <li>
                                        адрес места жительства и адреса места фактического
                                        проживания;
                                    </li>
                                    <li>адрес для направления корреспонденции;</li>
                                    <li>идентификационный номер налогоплательщика (ИНН);</li>
                                    <li>страховой номер индивидуального лицевого счёта (СНИЛС);</li>
                                    <li>реквизиты банковского счета;</li>
                                    <li>
                                        IP-адреса устройств, с которых осуществляется вход в ИС;
                                    </li>
                                    <li>
                                        cookie-файлы, параметры и настройки интернет-браузера,
                                        посредством которого осуществляется вход в ИС;
                                    </li>
                                    <li>
                                        международный идентификатор абонента-клиента, международный
                                        идентификатор пользовательского оборудования, с которого
                                        осуществляется вход в ИС.
                                    </li>
                                </ol>
                            </TwoColumnRow>
                            <TwoColumnRow title="«Правила»" headerWidth={30}>
                                правила информационной системы Оператора ПД, в которой
                                осуществляется выпуск цифровых финансовых активов, размещенные на
                                странице в информационно-телекоммуникационной сети «Интернет» по URL
                                - адресу: <LinkDocument />.
                            </TwoColumnRow>
                            <TwoColumnRow title="«Согласие»" headerWidth={30}>
                                настоящее согласие на обработку персональных данных.
                            </TwoColumnRow>
                        </AgreementSection>
                        <AgreementSection>
                            Все термины, встречающиеся в тексте Согласия с заглавной буквы и
                            содержание которых не раскрыто в нём, применяются в значении,
                            предусмотренном Правилами.
                        </AgreementSection>
                        <AgreementSection>
                            Заявитель дает Оператору ПД согласие на обработку Персональных данных
                            посредством:
                            <li>ознакомления с Согласием;</li>
                            <li>
                                проставления отметки о согласии, выраженной в форме «✓», в
                                специальном поле под заголовком «Даю согласие на обработку
                                персональных данных»;
                            </li>
                            <li>нажатия электронной кнопки «Войти».</li>
                        </AgreementSection>
                        <AgreementSection>
                            Обработка Персональных данных осуществляется Оператором ПД в следующих
                            целях:
                            <li>обеспечение прохождения Заявителем процедуры регистрации в ИС;</li>
                            <li>
                                осуществление Оператором ПД функций оператора ИС, предусмотренных
                                Правилами, пользовательским соглашением и действующим
                                законодательством Российской Федерации;
                            </li>
                            <li>рассмотрение обращений Заявителя;</li>
                            <li>оказание Заявителю информационной поддержки.</li>
                        </AgreementSection>
                        <AgreementSection>
                            Оператор ПД обрабатывает Персональные данные в объеме необходимом для
                            достижения каждой из вышеперечисленных целей следующими возможными
                            способами: сбор, запись, систематизация, накопление, хранение, уточнение
                            (обновление, изменение), использование, передача (предоставление,
                            доступ), обезличивание, блокирование, удаление, уничтожение, а также
                            осуществление любых иных действий с Персональными данными в соответствии
                            с действующим законодательством Российской Федерации.
                        </AgreementSection>
                        <AgreementSection>
                            В случае прохождения Заявителем процедуры регистрации в ИС и получения
                            статуса Пользователя:
                            <li>Согласие сохраняет свою силу;</li>
                            <li>ссылки на Заявителя считаются ссылками на Пользователя.</li>
                        </AgreementSection>
                        <AgreementSection>
                            Согласие действует в течение 10 лет с даты исключения Заявителя,
                            имеющего статус Пользователя, из Реестра Пользователей.
                        </AgreementSection>
                        <AgreementSection>
                            Для прекращения передачи и использования технических данных
                            (cookie-файлов, настроек интернет-браузера) Заявитель может отключить
                            соответствующую возможность в настройках интернет-браузера.
                        </AgreementSection>
                    </AgreementSectionList>
                </Box>
            }
        />
    )
}
