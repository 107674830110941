import { ConditionsModal, LinkDocument } from '@dltru/dfa-ui'
import { FC } from 'react'

import { IAgreementModal } from './types'

export const OISAgreement: FC<IAgreementModal> = ({ ...props }) => {
    return (
        <ConditionsModal
            title="Правила информационной системы ООО «Системы распределенного реестра»"
            {...props}
            text={
                <>
                    <h3>
                        Ссылка на файл: <LinkDocument title="Документ" />
                    </h3>
                </>
            }
        />
    )
}
